import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import React from 'react';

import bImgMarktIP from "../images/marktverkenning ip_cr.png"
import bImgComIP from "../images/commercieel plan ip.png"
import bImgGen from "../images/general.png"

import bImgMarktAD from "../images/marktverkenning ad.png"
import bImgAK from "../images/contract kalender ad.png"


export interface IDashboard {
    name: string;
    path: string;
    url: string;  // unique
    icon: React.JSX.Element;
    img: string;
}


/**
 * Dashboard voor inschrijvende partij.
 */
export const DASHBOARDS_IP: IDashboard[] = [
    {
        name: "Marktverkenning IP",
        url: "/marktverkenning-ip",
        path: 'shared:/ETIntell02/live/206. Marktverkenning IP',
        icon:  <DashboardRoundedIcon/>,
        img: bImgMarktIP
    },
    {
        name: "Commerciëel Plan",
        url: "/commercieel-plan-ip",
        path: 'shared:/ETIntell02/live/010. Commercieel Plan IP',
        icon:  <DashboardRoundedIcon/>,
        img: bImgComIP
    },
    {
        name: "Alert",
        url: "/alert-ip",
        path: 'shared:/ETIntell02/live/040. Alert IP',
        icon:  <DashboardRoundedIcon/>,
        img: bImgGen
    }
];

/**
 * Dashboard voor aanbestedende dienst.
 */
export const DASHBOARDS_AD: IDashboard[] = [
    {
        name: "Marktverkenning AD",
        url: "/marktverkenning-ad",
        path: 'shared:/ETIntell02/live/207. Marktverkenning AD',
        icon:  <DashboardRoundedIcon/>,
        img: bImgMarktAD
    },
    {
        name: "Aanbestedingskalender",
        url: "/aanbestedingskalender-ad",
        path: 'shared:/ETIntell02/live/210. Aanbestedingskalender',
        icon:  <DashboardRoundedIcon/>,
        img: bImgAK
    }
]

export const ZOEK_DASHBOARD: IDashboard = {
    name: "Zoeken",
    url: "/zoeken",
    path: 'shared:/ETIntell02/live/300. Zoeken',
    icon:  <SearchRoundedIcon/>,
    img: bImgGen
}