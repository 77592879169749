import React, {useState} from "react";
import Box from "@mui/material/Box";
import useCustomHeaders from "../ic3/useCustomHeaders";
import {IReportDefinition, IReporting} from "@ic3/reporting-api-embedded";
import DemoPrintButton from "../ic3/DemoPrintButton";
import Typography from "@mui/material/Typography";
import {IDashboard} from "../ic3/AvailableDashboards";
import {ProfileContext, useProfile} from "../util/profile";
import CreateProfileMessageBox from "../components/CreateProfileMessageBox";
import Meta from "../components/Meta";
import Container from "@mui/material/Container";
import ProfileCard from "../components/ProfileCard";
import {getProfile} from "../util/AvailableProfiles";
import {Popover} from "@mui/material";
import Button from "@mui/material/Button";
import {DashboardsDiv} from "../ic3/DashboardsDiv";

interface Props {
    dashboard: IDashboard;
    neededProfile: keyof ProfileContext;
}

export default function DashboardPage(props: Props) {

    useCustomHeaders();
    const context = useProfile();
    const profile = context[props.neededProfile];

    const [reporting, setReporting] = useState<IReporting>();
    const [reportDef, setReportDef] = useState<IReportDefinition | undefined>();
    const dashboard = props.dashboard;
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const profileSettings = getProfile(props.neededProfile);

    const handleResetDashboard = React.useCallback(() => {
        reporting?.fireAppNotification({type: "initialize-selection-all-widgets"});
    }, [reporting]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (profile == null) {
        return <CreateProfileMessageBox neededProfile={props.neededProfile}/>
    }

    return <>

        <Meta title={dashboard.name}/>
        <Container maxWidth="lg" sx={{flex: 0}}>

            <Box display={'flex'} flexDirection={'row'} gap={2} alignItems={'center'} pl={4}>
                <Typography variant={'h2'} pr={2}>{dashboard?.name}</Typography>
                <Box flex={1}/>
                <Button variant="outlined" onClick={handleClick}>
                    Gebruikt {profileSettings.profileName}
                </Button>
                <Button disabled={!reporting} variant="outlined" onClick={handleResetDashboard}>
                    Reset dashboard
                </Button>
                <DemoPrintButton reporting={reporting} reportDef={reportDef}/>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    slotProps={{
                        paper: {
                            elevation: 2
                        }
                    }}
                >
                    <ProfileCard profile={profile} profileSettings={profileSettings}/>
                </Popover>
            </Box>

        </Container>
        <Box sx={{overflowY: 'auto', flex: 1}}>
            <Container maxWidth="lg">

                <DashboardsDiv
                    dashboardPath={dashboard.path}
                    setReporting={setReporting}
                    setReportDef={setReportDef}
                    initParams={profile}
                />

            </Container>
        </Box>
    </>
}
