import React from "react";
import PricingPage from "./pricing";
import AuthPage from "./AuthPage";
import LegalPage from "./legal";
import PurchasePage from "./purchase";
import NotFoundPage from "./404";
import "./../util/analytics";
import {AuthProvider} from "../util/auth";
import {QueryClientProvider} from "../util/db";
import AdminPage from "./icAdmin";
import ConfirmSignupPage from "./signup";
import {ThemeProvider} from "../util/theme";
import ProfilePage from "./ProfilePage";
import {Router} from "../util/Router";
import {Route, Switch} from "react-router-dom";
import SettingsPage from "./SettingsPage";
import DashboardPage from "./DashboardPage";
import {DASHBOARDS_AD, DASHBOARDS_IP, ZOEK_DASHBOARD} from "../ic3/AvailableDashboards";
import PageWithSidebarAndAuthentication from "./PageWithSidebarAndAuthentication";
import {ProfileProvider} from "../util/profile";
import HomePage from "./HomePage";
import EditProfilePage from "./EditProfilePage";
import {PROFILES} from "../util/AvailableProfiles";
import {DashboardsDiv} from "../ic3/DashboardsDiv";

function App() {

    return (<QueryClientProvider>
        <ThemeProvider>
            <AuthProvider>
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <PageWithSidebarAndAuthentication>
                                <HomePage/>
                            </PageWithSidebarAndAuthentication>
                        </Route>

                        <Route exact path="/zoeken">
                            <PageWithSidebarAndAuthentication>
                                <DashboardsDiv dashboardPath={ZOEK_DASHBOARD.path}
                                                    appPath={"shared:/ETIntell02/basic"}/>
                            </PageWithSidebarAndAuthentication>
                        </Route>

                        {
                            DASHBOARDS_IP.map(i =>
                                <Route exact path={i.url} key={i.url}>
                                    <PageWithSidebarAndAuthentication>
                                        <ProfileProvider>
                                            <DashboardPage dashboard={i} neededProfile={"profile-ip"}/>
                                        </ProfileProvider>
                                    </PageWithSidebarAndAuthentication>
                                </Route>
                            )
                        }

                        {
                            DASHBOARDS_AD.map(i =>
                                <Route exact path={i.url} key={i.url}>
                                    <PageWithSidebarAndAuthentication>
                                        <ProfileProvider>
                                            <DashboardPage dashboard={i} neededProfile={"profile-ad"}/>
                                        </ProfileProvider>
                                    </PageWithSidebarAndAuthentication>
                                </Route>
                            )
                        }

                        {
                            PROFILES.map(i =>
                                <Route exact path={"/" + i.id} key={i.id}>
                                    <PageWithSidebarAndAuthentication>
                                        <ProfileProvider>
                                            <ProfilePage
                                                profileSettings={i}
                                            />
                                        </ProfileProvider>
                                    </PageWithSidebarAndAuthentication>
                                </Route>
                            )
                        }

                        {
                            PROFILES.map(i =>
                                <Route exact path={"/" + i.id + "/edit"} key={i.id}>
                                    <PageWithSidebarAndAuthentication>
                                        <ProfileProvider>
                                            <EditProfilePage profileSettings={i}/>
                                        </ProfileProvider>
                                    </PageWithSidebarAndAuthentication>
                                </Route>
                            )
                        }

                        <Route exact path="/pricing" component={PricingPage}/>

                        <Route exact path="/iccube-admin">
                            <PageWithSidebarAndAuthentication>
                                <AdminPage/>
                            </PageWithSidebarAndAuthentication>
                        </Route>

                        <Route exact path="/admin-app">
                            <PageWithSidebarAndAuthentication>
                                <DashboardsDiv appPath={"shared:/ETIntell02/admin"}/>
                            </PageWithSidebarAndAuthentication>
                        </Route>

                        <Route exact path="/admin-audit">
                            <PageWithSidebarAndAuthentication>
                                <DashboardsDiv appPath={"shared:/ETIntell02/audit"}/>
                            </PageWithSidebarAndAuthentication>
                        </Route>

                        <Route exact path="/auth/:type" component={AuthPage}/>

                        <Route exact path="/settings/:section">
                            <PageWithSidebarAndAuthentication>
                                <SettingsPage/>
                            </PageWithSidebarAndAuthentication>
                        </Route>

                        <Route exact path="/confirm-signup" component={ConfirmSignupPage}/>

                        <Route exact path="/legal/:section" component={LegalPage}/>

                        <Route exact path="/purchase/:plan">
                            <PageWithSidebarAndAuthentication>
                                <PurchasePage/>
                            </PageWithSidebarAndAuthentication>
                        </Route>

                        <Route component={NotFoundPage}/>
                    </Switch>
                </Router>
            </AuthProvider>
        </ThemeProvider>
    </QueryClientProvider>);
}

export default App;
