import React from "react";
import {useRouter} from "../util/Router";
import Section from "../components/Section";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";

export default function ConfirmSignupPage() {

    const router = useRouter();
    const confirmationUrl = String(router.query.confirmation_url);

    if (!(confirmationUrl.startsWith("https") && confirmationUrl.includes("supabase.co"))) {
        return <Container>
            <Alert severity={"error"}>{"The confirmation link you received is invalid"}</Alert>
        </Container>
    }

    return <Section>
        <Container>
            <p>Click the button below to confirm your signup</p>
            <Button variant={'contained'} href={confirmationUrl} color={'primary'}>Confirm signup</Button>
        </Container>
    </Section>

}
