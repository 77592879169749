import supabase from "./supabase";
import {AlertColor} from "@mui/material/Alert";

export async function getAccessToken() {
    const {
        data: {session},
    } = await supabase.auth.getSession();
    return session ? session.access_token : undefined;
}

// Make an API request to `/api/{path}`
export async function apiRequest(path: string, method = "GET", data: any) {
    return apiRequestRaw("api/" + path, method, data)
        .then(response => response.data);
}

// Make an API request to `/{path}`
export async function apiRequestRaw(path: string, method = "GET", data: any) {
    const accessToken = await getAccessToken();

    return fetch(`/${path}`, {
        method: method,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
        body: data ? JSON.stringify(data) : undefined,
    })
        .then((response) => response.json())
        .then((response) => {
            if (response.status === "error") {
                // Automatically signout user if accessToken is no longer valid
                if (response.code === "auth/invalid-user-token") {
                    supabase.auth.signOut();
                }

                throw new CustomError(response.code, response.message);
            } else {
                return response;
            }
        });
}

// Make an API request to any external URL
export function apiRequestExternal(url: string, method = "GET", data: any) {
    return fetch(url, {
        method: method,
        headers: {
            accept: "application/json",
            "Content-Type": "application/json",
        },
        body: data ? JSON.stringify(data) : undefined,
    }).then((response) => response.json());
}

// Create an Error with custom message and code
export class CustomError extends Error {
    readonly code: string;

    constructor(code: string, message: string) {
        super(message);
        this.code = code;
    }
}

export interface FormAlert {
    type: AlertColor;
    message: string;
}