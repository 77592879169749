import React from "react";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const useStyles = makeStyles(() => ({
    root: {
        // Add bottom margin if element below
        "&:not(:last-child)": {
            marginBottom: "1rem",
        },
    }, subtitle: {
        // Subtitle text generally isn't very long
        // so usually looks better to limit width.
        maxWidth: 700, // So we can have max-width but still
        // have alignment controlled by text-align.
        display: "inline-block",
    },
}));

function SectionHeader(props: any) {
    const classes = useStyles();

    const {subtitle, title, size, className, ...otherProps} = props;

    // Render nothing if no title or subtitle
    if (!title && !subtitle) {
        return null;
    }

    return (<Box
        component="header"
        className={classes.root + (props.className ? ` ${props.className}` : "")}
        {...otherProps}
    >
        {title && (<Typography
            variant={`h${size}` as any}
            gutterBottom={!!props.subtitle}
        >
            {title}
        </Typography>)}

        {subtitle && (<Typography variant="subtitle1" className={classes.subtitle}>
            {subtitle}
        </Typography>)}
    </Box>);
}

export default SectionHeader;
