import React from "react";
import PricingSection from "./PricingSection";

function SettingsActivateAccount(_props: any) {
    return <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Activeren"
        subtitle="U heeft een abonnement, maar uw account is nog niet actief? Klik op [Account activeren] en verstuur een mailtje naar onze support afdeling."
    />
}

export default SettingsActivateAccount;
