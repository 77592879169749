import React, {useCallback, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {Close} from '@mui/icons-material';
import {useTheme} from "@mui/styles";
import Button from "@mui/material/Button";
import {Theme} from "@mui/material";
import {IAppNotificationToken, IReportDefinition, IReporting} from "@ic3/reporting-api-embedded";

interface DemoPrintButtonProps {

    reporting?: IReporting;

    reportDef?: IReportDefinition;

}

export default function DemoPrintButton(props: DemoPrintButtonProps) {

    const {reporting, reportDef} = props;
    const [isOngoing, setIsOngoing] = useState<IAppNotificationToken>();
    const [showOngoing, setShowOngoing] = useState(false) /* delayed to prevent visual glitch when fast operation */;

    useEffect(() => {

        if (isOngoing) {
            const timer = setTimeout(() => setShowOngoing(true), 500);

            return () => {
                clearTimeout(timer);
            };

        } else {
            setShowOngoing(false);
        }

    }, [isOngoing]);

    const handlePrintDashboard = useCallback(() => {

        if (!isOngoing) {

            reporting?.fireAppNotification({
                type: "print-report", payload: {
                    paperOrientation: "portrait", paperSizeName: "A4", fileName: reportDef?.getName(),
                    marginTop: 10,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                }
            }, {
                onStarted: (token) => {
                    console.log("print started", token)
                    setIsOngoing(token);
                }, onSuccess: (token) => {
                    console.log("print success", token)
                    setIsOngoing(undefined);
                }, onError: (token, error) => {
                    console.log("print error", token, error)
                    setIsOngoing(undefined);
                }
            });

        } else {

            reporting?.cancelAppNotification(isOngoing) /* could display a dialog to confirm cancel */;

        }

    }, [reporting, isOngoing, reportDef]);

    const theme: Theme = useTheme();

    return (<Button disabled={!reporting || !reportDef} variant={"contained"} onClick={handlePrintDashboard}>
        {"Download PDF"}
        {showOngoing && <Box sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: 'rgba(66,66,66,0.6)',
        }}>
            <CircularProgress size={"1.4em"}/>
        </Box>}
        {showOngoing && <Box sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: 0.9,
            color: theme.palette.primary.main,
            "&:hover": {
                opacity: 0.9,
            }
        }}>
            <Close/>
        </Box>}
    </Button>);

}
