import React from "react";
import Meta from "../components/Meta";
import AuthSection from "./../components/AuthSection";
import {useRouter} from "../util/Router";

export default function AuthPage() {
    const router = useRouter();

    return (
        <>
            <Meta title="Auth"/>
            <AuthSection
                bgColor="default"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                type={router.query.type}
                providers={undefined}  // TODO (tom) add auth providers
                afterAuthPath={router.query.next || "/"}
            />
        </>
    );
}