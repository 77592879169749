import ImageBox from "./ImageBox";
import bImg from "../images/tender-insights-report.jpg";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";
import React from "react";
import {ProfileContext} from "../util/profile";
import {PROFILES} from "../util/AvailableProfiles";
import {getRef} from "./ProfileCard";
import {useRouter} from "../util/Router";

interface Props {
    neededProfile: keyof ProfileContext;
}

export default function CreateProfileMessageBox(props: Props) {

    const profileSettings = PROFILES.find(i => i.id === props.neededProfile)!;
    const router = useRouter();

    return <Box display={'flex'} height={'100%'} alignItems={'center'} flexDirection={'column'}
                justifyContent={'center'} gap={4} mb={6}>
        <ImageBox img={bImg}/>
        <Typography>
            Om de rapportages te bekijken heb je een profiel nodig.
        </Typography>
        <Button component={Link} to={`/${props.neededProfile}/edit` + getRef(router.pathname)}
                variant={'contained'}>{profileSettings.createButtonText}</Button>
    </Box>
}