import React, {createContext, useContext} from "react";
import {DashboardParams, useDashboardProfile} from "./db";
import {useAuth} from "./auth";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {Alert} from "@mui/material";


export interface ProfileContext {
    "profile-ip"?: DashboardParams;
    "profile-ad"?: DashboardParams;
}

const profileContext = createContext<ProfileContext>({});

export function useProfile() {
    return useContext(profileContext);
}

export function ProfileProvider({children}: { children: React.JSX.Element }) {

    const auth = useAuth();

    const profileData = useDashboardProfile(auth.user.uid);

    if (profileData.status === "loading") {

        return <Box>
            <CircularProgress size={32}/>
        </Box>

    } else if (profileData.status === "error") {

        return <Box mb={3}>
            <Alert severity="error">{profileData.error?.message}</Alert>
        </Box>

    }

    let parsedProfileData: ProfileContext;
    try {
        parsedProfileData = {
            "profile-ip": profileData.data['profile-ip'] ? JSON.parse(profileData.data['profile-ip']) : undefined,
            "profile-ad": profileData.data['profile-ad'] ? JSON.parse(profileData.data['profile-ad']) : undefined,
        }
    }
    catch (e) {
        return <Box mb={3}>
            <Alert severity="error">{"Error parsing profile data: " + String(e)}</Alert>
        </Box>
    }

    return <profileContext.Provider value={parsedProfileData}>
        {children}
    </profileContext.Provider>

}
