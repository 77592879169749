import logoInverted from "../images/ETIntell - night.svg";
import logo from "../images/ETIntell - day.svg";
import {useDarkMode} from "../util/theme";
import {Link} from "react-router-dom";
import * as React from "react";

export function EtiLogo(props: any) {

    const darkMode = useDarkMode();
    const logoImage = darkMode.isDarkMode ? logoInverted : logo;

    return <Link to="/">
        <img src={logoImage} alt="Logo" height={props.height}/>
    </Link>
}