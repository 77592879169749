import React from "react";
import Meta from "../components/Meta";
import LegalSection from "./../components/LegalSection";
import {useRouter} from "../util/Router";

export default function LegalPage() {
    const router = useRouter();

    return (
        <>
            <Meta title="Legal"/>
            <LegalSection
                bgColor="default"
                size="normal"
                bgImage=""
                bgImageOpacity={1}
                section={router.query.section}
                key={router.query.section}
            />
        </>
    );
}
