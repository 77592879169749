import React, {useCallback, useState} from "react";
import Container from "@mui/material/Container";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import {useAuth} from "../util/auth";
import Typography from "@mui/material/Typography";
import {updateAlertSetting, useAlertSettings} from "../util/db";
import Box from "@mui/material/Box";
import {Alert} from "@mui/material";
import {FormAlert} from "../util/util";
import CircularProgress from "@mui/material/CircularProgress";

export default function SettingsAlert(_props: any) {

    const auth = useAuth();
    const alertSettings = useAlertSettings(auth.user.uid);
    const [formAlert, setFormAlert] = useState<FormAlert>();
    const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback((newValue: boolean) => {

        updateAlertSetting(auth.user.id, newValue)
            .catch((error: Error) => {
                setFormAlert({type: "error", message: error.message});
                setLoading(false);
            });

    }, [auth.user.id]);

    const isLoading = alertSettings.status !== "success" || loading;

    return (
        <Container maxWidth="xs">

            <Typography>ETIntell checks each morning if you have new lots of interest to you.
                If there are any, ETIntell will email them to you.</Typography>
            <FormGroup sx={{mt: 2}}>
                <FormControlLabel
                    disabled={isLoading}
                    control={
                        <Switch checked={alertSettings.data?.enabled ?? false}
                                onChange={(event, checked) => handleSubmit(checked)}
                        />
                    }
                    label={"Enable alerts"}
                />
            </FormGroup>
            <FormHelperText>Alerts will be emailed to {auth.user.email}</FormHelperText>

            {isLoading && <CircularProgress size={24}/>}

            {formAlert && <Box mb={4}>
                <Alert severity={formAlert.type}>{formAlert.message}</Alert>
            </Box>}

        </Container>
    );

}
