import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, {useCallback, useState} from "react";
import {Button, CardActions} from "@mui/material";
import {Link} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import {PROFILES, ProfileSettings} from "../util/AvailableProfiles";
import {IEventContent} from "@ic3/reporting-api-embedded/dist/IReporting";
import {DashboardParams, updateDashboardProfile} from "../util/db";
import {useAuth} from "../util/auth";
import {useRouter} from "../util/Router";
import {IEventContentItem} from "@ic3/reporting-api-embedded";

interface Props {
    profileSettings: ProfileSettings;
    profile: DashboardParams;
}

export function getRef(location: string) {

    if (PROFILES.some(i => "/" + i.id === location)) {
        return "";
    }

    return "?from=" + location;

}

export default function ProfileCard(props: Props) {

    const [deleteLoading, setDeleteLoading] = useState(false);

    const router = useRouter();
    const auth = useAuth();
    const deleteProfile = useCallback(() => {

        setDeleteLoading(true);

        updateDashboardProfile(auth.user.uid, props.profileSettings.dbField, undefined)
            .finally(() => {
                setDeleteLoading(false);
            });

    }, [auth.user.uid, props.profileSettings.dbField]);

    return <Card variant="outlined" sx={{maxWidth: 345, minWidth: 345}}>
        <CardContent sx={{display: "flex", flexDirection: "column"}}>
            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                Jouw {props.profileSettings.profileName}
            </Typography>
            <Box sx={{maxHeight: '400px', overflowY: 'auto', display: "flex", flexDirection: "column"}}>
                {
                    props.profileSettings.parts.map(i => <React.Fragment key={i.name}>
                        <Typography variant={'caption'} mt={2}>
                            {i.name}
                        </Typography>
                        <NamePart profileElement={props.profile[i.event]} event={i.event}/>
                    </React.Fragment>)
                }
            </Box>
        </CardContent>
        <CardActions>
            <Button component={Link} to={"/" + props.profileSettings.id + "/edit" + getRef(router.pathname)}>Profiel
                bewerken</Button>
            <Box sx={{flex: '1 1 auto'}}/>
            {
                deleteLoading && <CircularProgress size={20}/>
            }
            <Button component={"div"} color={'error'} onClick={deleteProfile}>verwijderen</Button>
        </CardActions>
    </Card>

}

function NamePart(props: { profileElement: IEventContent | null; event: string }) {

    const profileElement = props.profileElement;

    if (profileElement == null) {
        return <Typography>—</Typography>;
    }

    const caption = parseEventContentCaption(profileElement, props.event);

    if (!caption) {
        return <Typography>—</Typography>;
    }

    if (Array.isArray(caption)) {
        return <Box display={'flex'} flexDirection={'column'}>
            {
                caption.map(i => <Typography key={i} title={i} sx={{
                    whiteSpace: 'nowrap',
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis'
                }}>{i}</Typography>)
            }
        </Box>
    }

    return <Typography>{caption}</Typography>;
}


function parseEventContentCaption(eventContent: IEventContent, event: string): string | string[] {

    const parseUN = (un: string): string => {

        const idx = un.indexOf(".&[");

        if (idx < 0) {
            return un;
        }

        const caption = un.slice(idx + 3, un.length - 1).trim();

        if (caption.endsWith("]")) {
            return caption.slice(0, caption.length - 2);
        }

        return caption;
    }

    const parseCaption = (event: IEventContentItem): string | string[] => {

        if (event.caption === event.uniqueName) {

            const isArrayIdx = event.uniqueName.indexOf("],[");

            if (isArrayIdx >= 0) {
                return event.uniqueName.split("],[").map(i => {
                    return parseUN(i);
                });
            }

            return parseUN(event.uniqueName);
        }

        return event.caption;


    }

    if (Array.isArray(eventContent)) {
        return eventContent.map(i => parseCaption(i)).flat();
    }

    if (event === "keywords") {
        return eventContent.caption;
    }

    if (event === "AD_name" || event === "Company") {

        const un = eventContent.uniqueName.slice(1, eventContent.uniqueName.length - 1).trim();

        return un.split("],[").map(i => {
            const idx = i.indexOf(".&[");

            if (idx < 0) {
                return i;
            }

            if (i.endsWith("]")) {
                return i.slice(idx + 3, i.length - 1);
            }

            return i.slice(idx + 3, un.length).trim();
        });
    }

    return parseCaption(eventContent);

}