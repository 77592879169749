import React, {useCallback, useMemo, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Alert, Button} from "@mui/material";
import {IReporting} from "@ic3/reporting-api-embedded";
import {produce} from "immer";
import {DashboardParams, updateDashboardProfile} from "../util/db";
import {useAuth} from "../util/auth";
import {useProfile} from "../util/profile";
import useCustomHeaders from "../ic3/useCustomHeaders";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Meta from "../components/Meta";
import Container from "@mui/material/Container";
import {useRouter} from "../util/Router";
import CircularProgress from "@mui/material/CircularProgress";
import {ProfileSettings} from "../util/AvailableProfiles";
import {FormAlert} from "../util/util";
import {DashboardsDiv} from "../ic3/DashboardsDiv";


interface Props {
    profileSettings: ProfileSettings;
}

export default function EditProfilePage(props: Props) {

    const {profileSettings} = props;
    const [activeStep, setActiveStep] = React.useState(0);

    useCustomHeaders();
    const auth = useAuth();
    const profile = useProfile();
    const router = useRouter();

    const [formAlert, setFormAlert] = useState<FormAlert>();

    const [reporting, setReporting] = useState<IReporting>();
    const [params, setParams] = useState<DashboardParams>(profile?.[props.profileSettings.id] ?? {});
    const [submitLoading, setSubmitLoading] = useState(false);

    useMemo(() => {

        if (reporting) {

            profileSettings.parts.forEach(pSetting => {
                const eventName = pSetting.event;
                reporting.onEvent(eventName, (eventContent) => {
                    setParams(old => produce(old, draft => {
                        draft[eventName] = eventContent;
                    }));
                });
            })

        }

    }, [reporting, profileSettings.parts]);

    const setProfile = useCallback(() => {

        const validatedParams: DashboardParams = {};

        for (const part of profileSettings.parts) {
            const error = validatePart(part, params);
            if (error) {
                setFormAlert({type: "error", message: error});
                return;
            }
            validatedParams[part.event] = params[part.event];
        }

        setSubmitLoading(true);

        updateDashboardProfile(auth.user.uid, profileSettings.dbField, validatedParams)
            .then(() => {
                setFormAlert({type: "success", message: "Profiel is gezet"});
                setSubmitLoading(false);
                setTimeout(() => {

                    const goTo = router.query['from'];
                    if (goTo != null) {
                        router.push(goTo);
                    } else {
                        router.push("/" + profileSettings.id);
                    }

                }, 1500);
            })
            .catch((error: Error) => {
                setFormAlert({type: "error", message: error.message});
                setSubmitLoading(false);
            });

    }, [params, auth.user.uid, profileSettings, router]);

    const handleNext = () => {
        const part = profileSettings.parts[activeStep];
        if (part == null) {
            setFormAlert({type: "error", message: "Part not found"});
            return;
        }
        const error = validatePart(part, params);
        if (error) {
            setFormAlert({type: "error", message: error});
        } else {
            setFormAlert(undefined);
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    return (<>
        <Meta title="Profile"/>
        <Container maxWidth="md">

            <Box display={'flex'} flexDirection={'column'} p={2} gap={2}>

                <Typography variant={'h2'}>
                    Profiel instellen
                </Typography>

                <Box sx={{width: '100%'}} mt={3}>
                    <Stepper nonLinear activeStep={activeStep}>
                        {profileSettings.parts.map((pSetting, index) => (
                            <Step key={pSetting.event} completed={index < activeStep}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                    {pSetting.name}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    <div>

                        <React.Fragment>
                            <Typography sx={{mt: 2, mb: 1, py: 1}}>
                                Step {activeStep + 1}
                            </Typography>
                            {
                                <Box height={'600px'} overflow={'auto'}>
                                    {
                                        profileSettings.parts[activeStep].dashboard && <DashboardsDiv
                                            dashboardPath={profileSettings.parts[activeStep].dashboard}
                                            setReporting={setReporting}
                                            initParams={params}
                                        />
                                    }
                                </Box>
                            }
                            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, alignItems: 'center'}}>
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{mr: 1}}
                                >
                                    Back
                                </Button>
                                <Box sx={{flex: '1 1 auto'}}/>
                                {
                                    submitLoading && <CircularProgress size={20}/>
                                }
                                {
                                    activeStep + 1 < profileSettings.parts.length ?
                                        <Button onClick={handleNext} sx={{mr: 1}}>
                                            Next
                                        </Button>
                                        :
                                        <Button onClick={setProfile}>
                                            Finish
                                        </Button>
                                }
                            </Box>
                        </React.Fragment>
                    </div>
                </Box>

                {formAlert && <Box mb={4}>
                    <Alert severity={formAlert.type}>{formAlert.message}</Alert>
                </Box>}

            </Box>

        </Container>

    </>);

}

function validatePart(part: ProfileSettings['parts'][number], params: DashboardParams): string | undefined {
    const eventValue = params[part.event];
    const error = part.validate && part.validate(eventValue);
    if (error) {
        return error;
    }
    if (Array.isArray(eventValue) && eventValue.length > 20) {
        return "Je kunt niet meer dat 20 items selecteren";
    }
    if (!Array.isArray(eventValue) && eventValue != null && eventValue.uniqueName.split(".&[").length > 20) {
        return "Je kunt niet meer dat 20 items selecteren";
    }
    return undefined;
}