import {IReportDefinition, IReporting} from "@ic3/reporting-api-embedded";
import {DashboardParams} from "../util/db";
import {IReportParam} from "@ic3/reporting-api-embedded/dist/IReporting";

export function setDashboard(reporting: IReporting, dashboardPath: string, setReportDef?: (report: IReportDefinition | undefined) => void,
                             params?: DashboardParams) {

    console.log("[ic3Div] fireEvent(selDash)", dashboardPath);

    if (reporting != null && dashboardPath != null) {

        reporting.openReport({

            path: dashboardPath,

            onDefinition: (report) => {
                setReportDef && setReportDef(report);
            },

            onError: (error) => {
                setReportDef && setReportDef(undefined);
                console.error("openReport error", error);
                return true /* handled */;
            },

            params: params != null ? toParams(params) : undefined

        });
    }

}

function toParams(p: DashboardParams) {
    const params: IReportParam[] = [];

    Object.keys(p).map(k => {

        const value = p[k];
        if (value != null
            && ((Array.isArray(value) && value[0].uniqueName) || (!Array.isArray(value) && value.uniqueName))) {

            params.push({
                channelName: k,
                value: value
            });

        }
    });

    return params;
}