import React, {useState} from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AuthForm from "./AuthForm";
import AuthSocial from "./AuthSocial";
import {useRouter} from "../util/Router";
import {FormAlert} from "../util/util";

function Auth(props: any) {
    const router = useRouter();
    const [formAlert, setFormAlert] = useState<FormAlert>();

    const handleAuth = (_user: any) => {
        router.push(props.afterAuthPath);
    };

    const handleFormAlert = (data: any) => {
        setFormAlert(data);
    };

    return (
        <>
            {formAlert && (
                <Box mb={3}>
                    <Alert severity={formAlert.type}>{formAlert.message}</Alert>
                </Box>
            )}

            <AuthForm
                type={props.type}
                buttonAction={props.buttonAction}
                onAuth={handleAuth}
                onFormAlert={handleFormAlert}
            />

            {["signup", "signin"].includes(props.type) && (
                <>
                    {props.providers && props.providers.length && (
                        <>
                            <Box textAlign="center" fontSize={12} my={2}>
                                OR
                            </Box>
                            <AuthSocial
                                buttonAction={props.buttonAction}
                                providers={props.providers}
                                showLastUsed={true}
                                onAuth={handleAuth}
                                onError={(message: any) => {
                                    handleFormAlert({
                                        type: "error",
                                        message: message,
                                    });
                                }}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default Auth;
