import {ProfileContext} from "./profile";
import {IEventContent} from "@ic3/reporting-api-embedded/dist/IReporting";
import ChecklistRoundedIcon from "@mui/icons-material/ChecklistRounded";
import * as React from "react";
import EngineeringRoundedIcon from "@mui/icons-material/EngineeringRounded";

export interface ProfileSettings {
    id: keyof ProfileContext;
    url: string;
    dbField: string;
    name: string;
    icon: React.JSX.Element;
    title: string;
    createButtonText: string;
    profileName: string;
    parts: {
        name: string;
        event: string;
        dashboard: string;
        validate?: (value: IEventContent | null) => string | void;
    }[];
}

export const PROFILES: ProfileSettings[] = [
    {
        id: "profile-ip",
        url: "/profile-ip",
        dbField: "profile_ip",
        name: "Inschrijvende partij",
        icon: <EngineeringRoundedIcon/>,
        title: "Bedrijfsprofiel Instellen",
        createButtonText: "Bedrijfsprofiel aanmaken",
        profileName: "bedrijfsprofiel",
        parts: [
            {
                name: "Land",
                event: "NUTS_Country",
                dashboard: "shared:/ETIntell02/live/002. IP-Profiel Setup Land",
                validate: value => {
                    if (isEmpty(value)) {
                        return "Kies eerst een land";
                    }
                }
            },
            {
                name: "Bedrijf",
                event: "Company",
                dashboard: "shared:/ETIntell02/live/003. IP-Profiel Setup Bedrijf",
                validate: value => {
                    if (isEmpty(value)) {
                        return "Kies eerst een contractant";
                    }
                }
            },
            {
                name: "CPV-codes",
                event: "CPV",
                dashboard: "shared:/ETIntell02/live/004. IP-Profiel Setup CPV"
            },
            {
                name: "Zoektermen",
                event: "keywords",
                dashboard: "shared:/ETIntell02/live/005. IP-Profiel Setup Zoektermen"
            },
        ]
    },
    {
        id: "profile-ad",
        url: "/profile-ad",
        dbField: "profile_ad",
        name: "Aanbestedende dienst",
        icon: <ChecklistRoundedIcon/>,
        title: "Profiel Aanbestedende Dienst instellen",
        createButtonText: "AD-profiel aanmaken",
        profileName: "AD-profiel",
        parts: [
            {
                name: "Land",
                event: "NUTS_Country",
                dashboard: "shared:/ETIntell02/live/002. IP-Profiel Setup Land",
                validate: value => {
                    if (isEmpty(value)) {
                        return "Kies eerst een land";
                    }
                }
            },
            {
                name: "Aanbestedende Dienst",
                event: "AD_name",
                dashboard: "shared:/ETIntell02/live/006. AD-Profiel Setup Naam",
                validate: value => {
                    if (isEmpty(value)) {
                        return "Kies eerst een aanbestedende dienst";
                    }
                }
            },
            {
                name: "CPV-codes",
                event: "CPV",
                dashboard: "shared:/ETIntell02/live/007. AD-Profiel Setup CPV"
            },
            {
                name: "Zoektermen",
                event: "keywords",
                dashboard: "shared:/ETIntell02/live/005. IP-Profiel Setup Zoektermen"
            }
        ]
    }
];

export function getProfile(id: string) {
    return PROFILES.find(i => i.id === id)!;
}

function isEmpty(param: IEventContent | null) {
    return param == null || !(Array.isArray(param) ? param[0] : param).uniqueName;
}
