import React, {useEffect, useMemo, useRef, useState} from 'react';
import {DashboardsLoaderDivContext, IReportDefinition, IReporting} from '@ic3/reporting-api-embedded';
import Box from "@mui/material/Box";
import {DashboardParams} from "../util/db";
import {setDashboard} from "./dashboardUtils";

interface IDashboardsDiv {

    dashboardPath?: string;
    appPath?: string;
    setReporting?: (ic3: IReporting) => void;
    setReportDef?: (report: IReportDefinition | undefined) => void;

    // Load these params when opening the dashboard.
    initParams?: DashboardParams;

}


export function DashboardsDiv(props: IDashboardsDiv) {

    const {
        dashboardPath, appPath, setReporting, setReportDef,
        initParams
    } = props;

    // Used only when opening the report the first time. This prevents opening the report each time the params update.
    const initReportParams = useRef(initParams);

    // Update initReportParams to the latest version
    useMemo(() => {
        initReportParams.current = initParams;
    }, [initParams]);

    const context = useMemo(() => {
        return new DashboardsLoaderDivContext({
            customHeaders: "dashboards"
        });
    }, []);

    const refContainer = useRef<HTMLDivElement>(null);

    const [error, setError] = useState<string>("");
    const [internalReporting, setInternalReporting] = useState<IReporting>();

    useMemo(() => {
        console.log("[ic3Div] ic3ready : opening " + appPath);
        internalReporting && appPath && internalReporting.openReportApp({path: appPath});
    }, [internalReporting, appPath]);

    useMemo(() => {
        internalReporting && dashboardPath && setDashboard(internalReporting, dashboardPath, setReportDef, initReportParams.current);
    }, [dashboardPath, internalReporting, setReportDef]);

    useEffect(() => {

        if (refContainer.current) {

            const options = {
                uid: "1",
                container: refContainer.current,
                resizingContainer: undefined
            }

            refContainer.current && context.loadLibsAndInitialize(options)
                .then(ic3 => {
                    console.log("[ic3Div] ic3ready : ", ic3);
                    setInternalReporting(ic3);
                    setReporting != null && setReporting(ic3);
                })
                .catch(reason => setError(reason));

        }

    }, [context, refContainer, setError, setInternalReporting, setReporting]);

    return <Box ref={refContainer} flex={1} sx={appPath ? {
        height: "100%", overflow: "auto"
    } : undefined}>{error}</Box>;
}