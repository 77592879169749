import React from "react";
import Meta from "../components/Meta";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, CardActions} from "@mui/material";
import {useProfile} from "../util/profile";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {Link} from "react-router-dom";
import {ProfileSettings} from "../util/AvailableProfiles";
import SectionHeader from "../components/SectionHeader";
import ProfileCard from "../components/ProfileCard";

interface Props {
    profileSettings: ProfileSettings;
}

export default function ProfilePage(props: Props) {

    const context = useProfile();
    const profile = context[props.profileSettings.id];

    return (<>
        <Meta title={props.profileSettings.title}/>
        <Container maxWidth="md">

            <SectionHeader
                title={props.profileSettings.title}
                subtitle={`Met een ${props.profileSettings.profileName} stellen we de rapportages af op jouw gegevens`}
                size={2}
            />

            <Box display={'flex'} flexDirection={'column'} mt={10}>

                {
                    profile == null
                        ? <Card variant="outlined" sx={{maxWidth: 345, minWidth: 345}}>
                            <CardContent sx={{display: "flex", flexDirection: "column"}}>
                                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                    Jouw {props.profileSettings.profileName}
                                </Typography>
                                <Typography mt={2}>
                                    Je hebt nog geen {props.profileSettings.profileName}. Met de knop hieronder kun je een
                                    profiel aanmaken.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button component={Link}
                                        to={"/" + props.profileSettings.id + "/edit"}>{props.profileSettings.createButtonText}</Button>
                            </CardActions>
                        </Card>
                        : <ProfileCard profile={profile} profileSettings={props.profileSettings}/>
                }


            </Box>

        </Container>

    </>);

}

