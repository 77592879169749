import React from "react";
import useCustomHeaders from "../ic3/useCustomHeaders";
import Box from "@mui/material/Box";

export default function AdminPage() {

    useCustomHeaders();

    return <Box height={'100%'}>
        <iframe title="ic-admin" id="ic3-iframe" width={"100%"} height={"100%"}
                src={"/icCube/console/index.html?ic3customHeaders=admin&ic3configuration=admin"}/>
    </Box>

}
