import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {useForm} from "react-hook-form";
import {useAuth} from "../util/auth";

function AuthForm(props: {
    type: "signin" | "signup" | "forgotpass" | "changepass";
    onAuth: any;
    onFormAlert: any;
    buttonAction: any;
}) {
    const auth = useAuth();
    const [pending, setPending] = useState(false);
    const {handleSubmit, register, errors, getValues, reset} = useForm();

    const submitHandlersByType = {
        signin: ({email, pass}: any) => {
            return auth.signin(email, pass).then((user: any) => {
                // Call auth complete handler
                props.onAuth(user);
            });
        },
        signup: ({email, pass}: any) => {
            return auth.signup(email, pass).then(() => {
                setPending(false);
                reset();
                props.onFormAlert({
                    type: "success",
                    message: "Thanks for signing up! Please check your email to complete the process.",
                });
            });
        },
        forgotpass: ({email}: any) => {
            return auth.sendPasswordResetEmail(email).then(() => {
                setPending(false);
                // Show success alert message
                props.onFormAlert({
                    type: "success",
                    message: "Password reset email sent",
                });
            });
        },
        changepass: ({pass}: any) => {
            return auth.confirmPasswordReset(pass).then(() => {
                setPending(false);
                // Show success alert message
                props.onFormAlert({
                    type: "success",
                    message: "Your password has been changed",
                });
            });
        },
    };

    // Handle form submission
    const onSubmit = ({email, pass}: any) => {
        // Show pending indicator
        setPending(true);

        // Call submit handler for auth type
        submitHandlersByType[props.type]({
            email,
            pass,
        }).catch((error: any) => {
            setPending(false);
            // Show error alert message
            props.onFormAlert({
                type: "error",
                message: error.message,
            });
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container={true} spacing={2}>
                {["signup", "signin", "forgotpass"].includes(props.type) && (
                    <Grid item={true} xs={12}>
                        <TextField
                            variant="outlined"
                            type="email"
                            label="Email"
                            name="email"
                            placeholder="user@example.com"
                            error={!!errors.email}
                            helperText={errors.email && errors.email.message}
                            fullWidth={true}
                            inputRef={register({
                                required: "Please enter your email",
                            })}
                        />
                    </Grid>
                )}

                {["signup", "signin", "changepass"].includes(props.type) && (
                    <Grid item={true} xs={12}>
                        <TextField
                            variant="outlined"
                            type="password"
                            label="Password"
                            name="pass"
                            error={!!errors.pass}
                            helperText={errors.pass && errors.pass.message}
                            fullWidth={true}
                            inputRef={register({
                                required: "Please enter a password",
                            })}
                        />
                    </Grid>
                )}

                {["signup", "changepass"].includes(props.type) && (
                    <Grid item={true} xs={12}>
                        <TextField
                            variant="outlined"
                            type="password"
                            label="Confirm Password"
                            name="confirmPass"
                            error={!!errors.confirmPass}
                            helperText={errors.confirmPass && errors.confirmPass.message}
                            fullWidth={true}
                            inputRef={register({
                                required: "Please enter your password again",
                                validate: (value) => {
                                    if (value === getValues().pass) {
                                        return true;
                                    } else {
                                        return "This doesn't match your password";
                                    }
                                },
                            })}
                        />
                    </Grid>
                )}

                <Grid item={true} xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        disabled={pending}
                        fullWidth={true}
                    >
                        {!pending && <span>{props.buttonAction}</span>}

                        {pending && <CircularProgress size={28}/>}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

export default AuthForm;
