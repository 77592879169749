import React from "react";
import Meta from "../components/Meta";
import Box from "@mui/material/Box";
import SectionHeader from "../components/SectionHeader";
import Container from "@mui/material/Container";
import {DASHBOARDS_AD, DASHBOARDS_IP} from "../ic3/AvailableDashboards";
import DashboardCarousel from "../components/DashboardCarousel";
import Typography from "@mui/material/Typography";
import {DashboardsDiv} from "../ic3/DashboardsDiv";

export default function HomePage() {

    return <>

        <Meta title="Home"/>
        <Container maxWidth="md">

            <SectionHeader
                title={"Home"}
                subtitle={""}
                size={2}
                textAlign="center"
            />

            <Box display={"flex"} flexDirection={"column"} gap={2}>

                <Box height={'100px'}>
                    <DashboardsDiv
                        dashboardPath={"shared:/ETIntell02/live/020. Home Overzicht"}
                    />
                </Box>

                <Typography variant={'subtitle1'}>Inschrijvende Partij (IP)</Typography>
                <DashboardCarousel dashboards={DASHBOARDS_IP}/>
                <Typography variant={'subtitle1'} sx={{mt:2}}>Aanbestedende Dienst (AD)</Typography>
                <DashboardCarousel dashboards={DASHBOARDS_AD}/>

            </Box>

        </Container>
    </>
}
