import {QueryClient, QueryClientProvider as QueryClientProviderBase, useQuery,} from "react-query";
import supabase from "./supabase";
import {PostgrestSingleResponse} from "@supabase/supabase-js";
import React from "react";
import {IEventContent} from "@ic3/reporting-api-embedded/dist/IReporting";

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Fetch user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid: string) {
    // Manage data fetching with React Query: https://react-query.tanstack.com/overview
    return useQuery<any, any>(
        // Unique query key: https://react-query.tanstack.com/guides/query-keys
        ["user", {uid}],
        // Query function that fetches data
        () =>
            supabase
                .from("users")
                .select(`*, customers ( * )`)
                .eq("id", uid)
                .single()
                .then(handle),
        // Only call query function if we have a `uid`
        {enabled: !!uid}
    );
}

// Fetch user data (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid: string) {
    return supabase
        .from("users")
        .select(`*, customers ( * )`)
        .eq("id", uid)
        .single()
        .then(handle);
}

// Update an existing user
export async function updateUser(uid: string, data: any) {
    const response = await supabase
        .from("users")
        .update(data)
        .eq("id", uid)
        .then(handle);
    // Invalidate and refetch queries that could have old data
    await client.invalidateQueries(["user", {uid}]);
    return response;
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Fetch item data
export function useItem(id: string) {
    return useQuery(
        ["item", {id}],
        () => supabase.from("items").select().eq("id", id).single().then(handle),
        {enabled: !!id}
    );
}

// Fetch all items by owner
export function useItemsByOwner(ownerUid: string) {
    return useQuery(
        ["items", {owner: ownerUid}],
        () =>
            supabase
                .from("items")
                .select()
                .eq("owner", ownerUid)
                .order("createdAt", {ascending: false})
                .then(handle),
        {enabled: !!ownerUid}
    );
}

// Create a new item
export async function createItem(data: any) {
    const response = await supabase.from("items").insert([data]).then(handle);
    // Invalidate and refetch queries that could have old data
    await client.invalidateQueries(["items"]);
    return response;
}

// Update an item
export async function updateItem(id: string, data: any) {
    const response = await supabase
        .from("items")
        .update(data)
        .eq("id", id)
        .then(handle);
    // Invalidate and refetch queries that could have old data
    await Promise.all([
        client.invalidateQueries(["item", {id}]),
        client.invalidateQueries(["items"]),
    ]);
    return response;
}

export function useDashboardProfile(ownerUid: string) {
    return useQuery<any, Error>(
        ["dashboard-profile", ownerUid],
        () =>
            supabase
                .from("profiles")
                .select()
                .eq("owner", ownerUid)
                .limit(1)
                .then(handle)
                .then(data => {
                    return {
                        "profile-ad": data?.[0]?.['profile_ad'],
                        "profile-ip": data?.[0]?.['profile_ip']
                    }
                }),
        {enabled: !!ownerUid}
    );
}

export type DashboardParams = Record<string, IEventContent | null>;

export async function updateDashboardProfile(ownerUid: string, profileKey: string, params: DashboardParams | undefined) {
    await supabase
        .from("profiles")
        .upsert({owner: ownerUid, [`${profileKey}`]: params ? JSON.stringify(params) : null})
        .then(handle);
    await client.invalidateQueries(["dashboard-profile", ownerUid]);
}

// Delete an item
export async function deleteItem(id: string) {
    const response = await supabase
        .from("items")
        .delete()
        .eq("id", id)
        .then(handle);
    // Invalidate and refetch queries that could have old data
    await Promise.all([
        client.invalidateQueries(["item", {id}]),
        client.invalidateQueries(["items"]),
    ]);
    return response;
}

/**** HELPERS ****/

// Get response data or throw error if there is one
function handle(response: PostgrestSingleResponse<any>) {
    if (response.error) throw response.error;
    return response.data;
}

// React Query context provider that wraps our app
export function QueryClientProvider(props: any) {
    return (
        // @ts-ignore
        <QueryClientProviderBase client={client}>
            {props.children}
        </QueryClientProviderBase>
    );
}

/**** ALERTS ****/

export interface AlertSettings {
    enabled: boolean;
}

export function useAlertSettings(ownerUid: string) {
    return useQuery<any, Error, AlertSettings>(
        ["profile-alerts", ownerUid],
        () =>
            supabase
                .from("alerts")
                .select()
                .eq("owner", ownerUid)
                .limit(1)
                .then(handle)
                .then(data => {
                    return {
                        "enabled": Boolean(data?.[0]?.['enabled'])
                    }
                }),
        {enabled: !!ownerUid}
    );
}

export async function updateAlertSetting(ownerUid: string, newValue: boolean) {
    await supabase
        .from("alerts")
        .upsert({owner: ownerUid, enabled: newValue})
        .then(handle);
    await client.invalidateQueries(["profile-alerts", ownerUid]);
}
