import Box from "@mui/material/Box";
import React from "react";


interface Props {
    img: string;
}

export default function ImageBox(props: Props) {

    return <Box sx={{
        borderRadius: '50%',
        backgroundImage: `url(${props.img})`,
        width: '200px',
        height: '200px',
        backgroundPosition: "center center",
        backgroundSize: "cover",
        mb: 2
    }}/>
}