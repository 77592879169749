import {IDashboard} from "../ic3/AvailableDashboards";
import Card from "@mui/material/Card";
import {CardActions, CardMedia} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";
import React from "react";

interface Props {
    dashboards: IDashboard[];
}

export default function DashboardCarousel(props: Props) {

    return <Box display={"flex"} flexDirection={"row"} gap={4} flexWrap={"wrap"}>
        {
            props.dashboards.map(i => {
                return <Card variant="outlined" sx={{maxWidth: 300, minWidth: 250}} key={i.path}>
                    <CardMedia
                        component="img"
                        alt={i.name + " image"}
                        height="140"
                        image={i.img}
                    />
                    <CardContent>
                        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                            Rapportage
                        </Typography>
                        <Typography variant="h5" component="div">
                            {i.name}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" component={Link} to={i.url}>Ga naar dashboard</Button>
                    </CardActions>
                </Card>
            })
        }
    </Box>

}