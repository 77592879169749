import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {CardActionArea} from '@mui/material';
import Button from "@mui/material/Button";
import {useDarkMode} from "../util/theme";
import React from 'react';


function UpgradeCard() {

    const mode = useDarkMode();

    return <Card sx={{background: mode.isDarkMode ? '#935916' : '#fdf0e1'}} elevation={0}>
        <CardActionArea>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    Let op
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Uw account is nog niet geactiveerd. Klik op Activeren.
                </Typography>
                <Button variant={'contained'} sx={{mt: 2}} href={'/settings/activate-account'}>Activeren</Button>
            </CardContent>
        </CardActionArea>
    </Card>
}

export default UpgradeCard
